import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { useForm } from "react-hook-form";
import { ValidationService } from "../../utils/Validation";
import Input from "../../components/Input/Input";
import Btn from "../../components/Button/Button";
import LoginEndpoints from "./Login.endpoints";
import useApiService from "../../services/api.service";
import logo from "../../assets/images/logo.svg";
import { useAppContext } from "../../AppContext/App.context";
import * as actions from "../../AppContext/actions/actions";
import useToast from "../../components/Toast/hooks/useToast";
import useLocalStorage from "../../utils/localStorage";
import useLoader from "../../hooks/useLoader";
import focusInput from "../../components/Toast/hooks/focusInput";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CommonEndpoints from "../../services/commonApi.service";
import { userPermissionRoles } from "../../constants/userPermissionRoles";
import Phone from "../../components/phone-picker/phone-picker";
import { themeColor } from "../../constants/themeColor";
import { Grid, Typography } from "@material-ui/core";
import signup1 from "../../assets/images/signup1.png";
import rebrand from "../../assets/images/rebranding_image.png";
import PasswordLoginComponent from "./PasswordLoginComponent";
import LoginViaOtpEndpoints from "../LoginViaOtp/LoginViaOtp.endpoints";

const Login = (props: any) => {
  useLayoutEffect(() => {
    // checkCustomData();
  }, []);
  const { t, i18n } = useTranslation(["Login", "Common"]);
  const { register, handleSubmit, errors } = useForm();
  const [appData]: any = useAppContext();
  const customData = useLocalStorage.getItem("CustomData");
  const toaster = useToast();
  const history = useHistory();
  const ApiService = useApiService();
  const loader: any = useLoader();
  // const [appData]: any = useAppContext();

  const domainData: any = useLocalStorage.getItem("domain_data");
  const {
    register: registerForgotPassword,
    handleSubmit: handleSubmitForgotPassword,
    errors: errorsPassword,
  } = useForm();

  const [showErrormsg, setShowErrormsg]: any = useState(true);
  const [showErrormsgPass, setShowErrormsgPass]: any = useState(true);

  const [inputFocus, setInputFocus]: any = useState({
    account: false,
    password: false,
  });
  const [inputFocusPassword, setInputFocusPassword]: any = useState({
    account: false,
  });

  const getPermissions = async (loginData: any) => {
    if (!Object.keys(loginData).length) {
      return;
    }
    let headers = {
      access_token: loginData.access_token,
    };
    let qureyParams = {
      role_id: loginData.role_id,
      limit: 1,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getRolesList(reqData)
    );
    const roleDetail: any = res.data.data[0];
    var TabPermission: any = {};
    let modpermsvalues: any = Object.values(roleDetail.modperms);
    modpermsvalues.map((mode: any) => {
      let moduleName: any =
        mode.module_alias.charAt(0).toUpperCase() +
        mode.module_alias.slice(1).replace(/_/g, "");
      for (var key in mode.permission) {
        TabPermission[`${userPermissionRoles[key].name}_${moduleName}`] =
          mode.permission[key].access_type;
      }
    });
    useLocalStorage.setItem("Permission", TabPermission);
    // window.location.href = "/dashboard";
    return true;
  };

  const submitResetPassword = (data: any) => {
    loader.showLoader();
    setShowErrormsgPass(false);
    const request = {
      email: data.email,
      user_type: 3,
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };

    const payload = {
      reqBody: request,
      headers: headers,
    };

    return ApiService.put(LoginEndpoints.customerForgotPassword(payload))
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        setForgetPassword(false);
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const obj = {
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
    OTP: {
      required: ValidationService.requiredValidator(),
    },
    password: {
      required: ValidationService.requiredValidator(),
    },
  };
  const [forgetPassword, setForgetPassword]: any = useState(false);

  // Focus
  const changeInputFocus = (data: any) => {
    let temp = { ...inputFocus };
    temp[data] = false;
    setInputFocus({ ...temp });
  };
  const changeInputFocusPassword = (data: any) => {
    let temp = { ...inputFocus };
    temp[data] = false;
    setInputFocusPassword({ ...temp });
  };

  // Phone Number
  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState();
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const [configSigninText, setConfigSigninText]: any = useState(
    props.text || customData?.colors?.login_heading
  );
  const [configCompany, setConfigCompanyText]: any = useState(
    props.company || customData?.colors?.company_name
  );
  const [showPasswordForm, setShowPasswordForm] = useState<Boolean>(false);
  const [isPasswordSelected, setIsPasswordSelected] = useState<String>("otp");
  const [userInfo, setUserInfo]: any = useState({
    phone: "",
    password: "",
  });
  useEffect(() => {
    if (appData?.formSettings?.colors?.company_name) {
      setConfigCompanyText(appData.formSettings?.colors?.company_name);
    }
    if (appData?.formSettings?.colors?.login_heading) {
      setConfigSigninText(appData.formSettings?.colors.login_heading);
    }
    if (appData.formSettings?.country_code) {
      updatePreSavedCountryCode(appData.formSettings?.country_code);
    }
  }, [appData.formSettings]);

  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  // Show Password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Form Submit
  const submitLoginForm = (data: any) => {
    loader.showLoader();
    setShowErrormsg(false);

    const request: any = {
      country_code: dialCode.current,
      phone_number: data.phone || userInfo.phone,
      app_version: "100.1",
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };

    return ApiService.post(LoginEndpoints.sendOTP(reqData))

      .then(async (res: any) => {
        console.log("Res Login: ", res);
        loader.hideLoader();

        if (res.message === "success") {
          // useLocalStorage.setItem("loginData", request);
          if (appData.loginData) {
            history.push(`/checkotp/${dialCode.current}/${userInfo.phone}`);
          }
          // dispatch(actions.onLoginResponse(res.data));
        } else {
        }
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
          inputref: () => {
            const temp = focusInput(err.message, inputFocus);
            if (temp) setInputFocus({ ...temp });
            else return;
          },
        });
      });
  };

  const submitPhoneNumberForm = (data: any) => {
    loader.showLoader();
    const request: any = {
      country_code: dialCode.current,
      phone_number: Number(userInfo.phone),
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    return ApiService.post(LoginEndpoints.customerVerify(reqData))
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        if (res.message === "success") {
          setUserInfo({ ...userInfo, phone: data.phone });
          setShowPasswordForm(true);
        }
      })
      .catch((err) => {
        // console.log(err, "errorHaiYe======");
        if (err.status === 401) {
          history.push("/signup", request);
        }
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const setBackgroundImage = () => {
    return `url(${signup1})`;
  };

  const handlePasswordSubmit = (e: any, data: any) => {
    loader.showLoader();
    setShowErrormsgPass(false);
    const request: any = {
      country_code: dialCode.current,
      phone_number: userInfo.phone,
      password: userInfo.password || data?.password,
      // otp_code: data.OTP,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      app_version: "100.1",
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    return ApiService.post(LoginViaOtpEndpoints.loginViaOTP(reqData))
      .then((res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        if (res.message === "success") {
          useLocalStorage.setItem("loginData", res.data);
          useLocalStorage.setItem("userAccessToken", res.data.access_token);
          // useLocalStorage.setItem("referenceId", 2);
          localStorage.setItem("loginReq", "1");
          window.location.href = "/jobs/list";
        }
        setForgetPassword(false);
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const passwordChangeHandler = (value: string | null) => {
    setUserInfo({ ...userInfo, password: value });
  };

  return (
    <div className="Login">
      {/* {checkCustomData()} */}

      <Grid container spacing={3}>
        <Grid item xs={12} style={{ overflow: "hidden" }}>
          <div className="Login__body">
            <img
              // className="align-self-center Login__logo"
              src={
                customData && customData.login_icon
                  ? customData.login_icon
                  : logo
              }
              alt=""
              width={210}
            ></img>

            <div className="">
              {forgetPassword ? (
                <Fragment>
                  <div className="Login__fp-title">
                    {t("login:Reset Password")}
                  </div>
                  <div className="my-3">
                    {t(
                      "Login:Enter your email to receive instructions on how to reset your password"
                    )}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="Login__fp-title">
                    {configSigninText
                      ? configSigninText
                      : t("Common:Welcome back! Sign in to continue")}
                  </div>
                </Fragment>
              )}
            </div>

            <div className="Login__card">
              {forgetPassword ? (
                <Fragment>
                  <form
                    onSubmit={handleSubmitForgotPassword(submitResetPassword)}
                  >
                    <Input
                      type="email"
                      id="email"
                      name="account"
                      refValue={registerForgotPassword(obj.email)}
                      errors={errorsPassword}
                      required={true}
                      inputFocus={inputFocusPassword}
                      setInputFocus={changeInputFocusPassword}
                      placeholder="Enter Your Email Address"
                      showErrormsg={showErrormsgPass}
                      label={t("Common:Email Address")}
                    ></Input>

                    <Grid container justifyContent="center">
                      <Btn
                        type={"Submit"}
                        btnText={t("Login:Reset Password")}
                        className={"Login__btn mt-3"}
                      />
                    </Grid>
                  </form>
                </Fragment>
              ) : showPasswordForm ? (
                <PasswordLoginComponent
                  userInfo={userInfo}
                  setForgetPassword={setForgetPassword}
                  setIsPasswordSelected={setIsPasswordSelected}
                  isPasswordSelected={isPasswordSelected}
                  passwordChangeHandler={passwordChangeHandler}
                  handleSubmit={handleSubmit}
                  submitLoginForm={submitLoginForm}
                  handleSubmitForgotPassword={handleSubmitForgotPassword}
                  handlePasswordSubmit={handlePasswordSubmit}
                  errorsPassword={errorsPassword}
                  inputFocusPassword={inputFocusPassword}
                  showErrormsgPass={showErrormsgPass}
                  setShowPasswordForm={setShowPasswordForm}
                />
              ) : (
                <Fragment>
                  <div style={{ textAlign: "center" }}>
                    <Typography className={"Login__trial-heading-text"}>
                      {" "}
                      {t("Common:Enter Mobile Number")}
                    </Typography>
                  </div>
                  <form onSubmit={handleSubmit(submitPhoneNumberForm)}>
                    <Phone
                      hideLabel={true}
                      showPhone={true}
                      setDialCode={fetchDialCode}
                      name="phone"
                      label={t("Common:Mobile Number")}
                      placeholder={`${t("Common:Mobile Number")}*`}
                      required={true}
                      defaultPhone={preSavedCountryCode}
                      // showErrormsg={showErrormsg}
                      errors={errors}
                      refValue={register(obj.phone)}
                      submitRequired={true}
                      value={userInfo.phone}
                      onchange={(e: any) =>
                        setUserInfo({ ...userInfo, phone: e.target.value })
                      }
                    />
                    {/* <div
                  className="d-flex justify-content-end Login__link"
                  onClick={() => setForgetPassword(true)}
                >
                  {t("Login:Forgot Password?")}
                </div> */}
                    <Grid
                      container
                      justifyContent="center"
                      style={{ marginTop: "-10px" }}
                    >
                      <Btn
                        type={"Submit"}
                        btnText={t("Common:Next")}
                        className={"Login__btn"}
                      />
                    </Grid>
                  </form>
                </Fragment>
              )}
            </div>
            <br />
            <br />
            <div
              className="Login__sign-up"
              onClick={() => {
                // loader.showLoader()
                setTimeout(() => {
                  history.push("/signup");
                  // loader.hideLoader()
                }, 100);
              }}
            >
              {/* {t(
                `Login:New to ${
                  configCompany ? configCompany : "NetworkON"
                }?  Sign Up`
              )} */}
              {t("Common:New to") +
                " " +
                (configCompany || "NetworkON") +
                "? " +
                t("Common:Sign Up")}
            </div>
            {forgetPassword ? (
              <div
                className="d-flex justify-content-end Login__link my-3"
                onClick={() => setForgetPassword(false)}
              >
                {t("login:Back to Sign In")}
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
